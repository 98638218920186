@import './variables.scss';

@font-face {
  font-family: 'SourceSansPro';
  src: url('../../sources/fonts/SourceSansPro-Regular.ttf');
}
@font-face {
  font-family: 'SFProDisplay';
  src: url('../../sources/fonts/SFProDisplay-Regular.ttf');
}

html,
body,
#root {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
  .ant-calendar-selected-date {
    background-color: $yellowColor !important;
  }
}
