@import 'sources/styles/styles.scss';

.content {
  margin-left: 31.5rem;
  min-height: 100% !important;
  overflow-x: unset !important;
  // margin-top: 12.4rem;
  flex: 1;
  display: flex;
  background-color: #fff !important;
}
.manageContent {
  // margin-left: 16.5%;
  margin-top: 12.4rem;
  flex: 1;
  display: flex;
  //
  background-color: #fff !important;
  // @include desktop4k {
  //   margin-top: 24.8rem;
  // }
}

.tabletContent {
  flex: 1;
  display: flex;
  background-color: #fff !important;
  .adminContent {
    margin-top: 8rem;
  }
}
