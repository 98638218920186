$tablet-width: 760px;
$desktop-width: 1368px;
$desktop4k-width: 2000px;
$desktop8k-width: 7000px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 1.5) {
    @content;
  }
}

@mixin small-screen {
  @media (min-width: #{$tablet-width}) and (max-width:1600px) {
    @content;
  }
}

@mixin desktop4k {
  @media (min-width: #{$desktop4k-width}) {
    @content;
  }
}

@mixin desktop8k {
  @media (min-width: #{$desktop8k-width}) {
    @content;
  }
}

$defaultFont: 'SFProDisplay';
$fontSF: 'SFProDisplay';
$fontSource: 'SourceSansPro';
$defaultFontSize: 14px;

$brandColor: #2fceeb;
$yellowColor: #ffa800;
$grayColor: #bdbdbd;

$base-font-size: 1em;
